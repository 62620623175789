import outdatedBrowser from "outdated-browser-rework";
import i18n from "@/i18n";
import "@/assets/css/outdated-browser-rework.css";
import { supportedLanguages } from "@/utils/locales";

/**
 * The supported browser versions have been studied in the following document.
 * The versions are rationaly set according to the local and worldwide usage.
 * See https://intranet.mindmaze.ch/confluence/display/COM/Browser+versions+usage
 */
outdatedBrowser({
  backgroundColor: "#f4dc70",
  textColor: "#041527", // blue-90
  browserSupport: {
    Chrome: 105,
    Edge: 105,
    Safari: 15.6,
    "Mobile Safari": false,
    Firefox: 104,
    Opera: false,
    Vivaldi: false,
    Yandex: false,
    IE: false,
  },
  isUnknownBrowserOK: false,
  messages: generateMessages(),
});

function generateMessages() {
  let messages = {};

  supportedLanguages.forEach((locale) => {
    const lang = locale.key;
    messages[lang] = {
      unsupported: i18n.t("App.OutdatedBrowserWarnings.unsupported", lang),
      outOfDate: i18n.t("App.OutdatedBrowserWarnings.outdated", lang),
      update: {
        web: i18n.t("App.OutdatedBrowserWarnings.updateMessage", lang, {
          updateLink:
            '<a target="_blank" rel="noopener noreferrer" href="https://browser-update.org/update-browser.html">https://browser-update.org/update-browser.html</a>',
        }),
      },
      url: null,
    };
  });
  return messages;
}
